import logoJue from '../assets/images/logo-jue.png';
import logoJueBig from '../assets/images/logo-jue-big.png';
import logoLeCaveau from '../assets/images/logo-lecaveau.png';
import logoSushi from '../assets/images/logo-sushi.png';
import logoLeCaveauBig from '../assets/images/logo-lecaveau-big.png';
import logoRistorante from '../assets/images/logo-ristorante.png';
import logoAperitivi from '../assets/images/logo-aperitivi.png';
import motionClub from '../assets/images/motion_club.jpg';
import motionJue from '../assets/images/motion_jue.jpg';
import contactMap from '../assets/images/contacts_map.png';
import insta_jue_1 from '../assets/images/insta_jue_1.jpg';
import insta_jue_2 from '../assets/images/insta_jue_2.jpg';
import insta_jue_3 from '../assets/images/insta_jue_3.jpg';
import fusion from '../assets/images/fusion.jpg';
// import intro_jue from '../assets/images/intro_jue.png';
// import intro_lecav from '../assets/images/intro_lecav.png';

export default {
  logoJue: logoJue,
  logoJueBig: logoJueBig,
  logoLeCaveau: logoLeCaveau,
  logoSushi: logoSushi,
  logoLeCaveauBig: logoLeCaveauBig,
  logoRistorante: logoRistorante,
  logoAperitivi: logoAperitivi,
  motionClub: motionClub,
  motionJue: motionJue,
  contactMap: contactMap,
  insta_jue_1: insta_jue_1,
  insta_jue_2: insta_jue_2,
  insta_jue_3: insta_jue_3,
  fusion: fusion
  // intro_jue: intro_jue,
  // intro_lecav: intro_lecav
};
