import routeKeys from '../routes/keys';
import logoJue from '../assets/images/logo-jue.png';

export default {
  logoJue: logoJue,
  links: [
    {
      text: 'Restaurant',
      path: routeKeys.Ristorante
    },
    {
      text: 'Drinks & Cocktails',
      path: routeKeys.Apericena
    },
    // {
    //   text: 'Events',
    //   path: routeKeys.Eventi
    // },
    {
      text: 'Contact',
      path: routeKeys.About
    }
  ]
};
