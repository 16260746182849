import React from 'react';
import { withRouter } from 'react-router-dom';
import noop from 'no-op';
import { BaseLink, HamburgerMenu } from '@jam3/react-ui';
import hamburgerNavData from '../../data/hamburger-menu';

import './MainTopNav.scss';

import HamburgerButton from '../HamburgerButton/HamburgerButton';

class MainTopNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false
    };
  }

  setMobileMenuState = () => {
    this.setState(prevState => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen
    }));
  };

  render() {
    const LinkComponent = this.props.linkComponent;

    return (
      <header>
        <div className="header__top">
          {this.props.ariaSiteTitle && <h1 className="only-aria-visible">{this.props.ariaSiteTitle}</h1>}
          <div className="header__top__content" aria-label={this.props.ariaNavLabel}>
            {this.props.ariaNavTitle && <h2 className="only-aria-visible">{this.props.ariaNavTitle}</h2>}
            <LinkComponent link={this.props.logoLink} aria-label={this.props.logoAriaLabel}>
              <img className="nav-logo nav-logo--jue" src={this.props.logoJue} alt={this.props.logoAlt} />
            </LinkComponent>
            <HamburgerButton onClick={this.setMobileMenuState} currentState={this.state.isMobileMenuOpen} />
          </div>
        </div>

        <HamburgerMenu
          {...hamburgerNavData}
          isMobileMenuOpen={this.state.isMobileMenuOpen}
          setIsMobileMenuOpen={this.setMobileMenuState}
          linkComponent={LinkComponent}
        >
          <section className="HamburgerMenu__info">
            <div className="HamburgerMenu__info__contact">
              <BaseLink
                className="HamburgerMenu__info__contact__link"
                link="https://www.instagram.com/jueclubmontecatini/"
              >
                {'#jueclubmontecatini'}
              </BaseLink>
            </div>
            <div className="HamburgerMenu__info__contact">
              <BaseLink className="HamburgerMenu__info__contact__link" link="tel:+3316610911">
                {'+39 331 6610911'}
              </BaseLink>
            </div>
            <div className="HamburgerMenu__info__contact">
              {/* <AtMailIcon /> */}
              <BaseLink className="HamburgerMenu__info__contact__link" link="mailto:info@jueclub.it">
                {'info@jueclub.it'}
              </BaseLink>
            </div>
          </section>
        </HamburgerMenu>
      </header>
    );
  }
}

MainTopNav.defaultProps = {
  logoAlt: 'logo',
  logoLink: '/',
  logoAriaLabel: 'Home',
  ariaNavLabel: 'Main Navigation',
  setIsMobileMenuOpen: noop,
  linkComponent: BaseLink
};

export default withRouter(MainTopNav);
