import routeKeys from '../routes/keys';

export default {
  links: [
    {
      text: 'Home',
      path: routeKeys.Landing
    },
    {
      text: 'Food',
      path: routeKeys.Ristorante
    },
    {
      text: 'Sushi Fusion',
      path: routeKeys.Sushi
    },
    {
      text: 'Drinks & Cocktails',
      path: routeKeys.Apericena
    },
    // {
    //   text: 'Events',
    //   path: routeKeys.Eventi
    // },
    {
      text: 'Contact',
      path: routeKeys.About
    }
  ]
};
