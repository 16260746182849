import React, { PureComponent } from 'react';
import classnames from 'classnames';

import '@jam3/react-ui/src/components/HamburgerButton/HamburgerButton.scss';

import { BaseButton } from '@jam3/react-ui';

const bars = [0, 1, 2].map(item => <span key={item} className={`bar ${item}`} />);

export default class HamburgerButton extends PureComponent {
  render() {
    return (
      <BaseButton
        className={classnames('HamburgerButton', this.props.currentState ? 'close' : 'idle')}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.props.onClick}
        tabIndex={this.props.tabIndex}
        aria-label={this.props.ariaLabel}
      >
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" />{' '}
        </svg>
        <div className="bars-container">{bars}</div>
      </BaseButton>
    );
  }
}
