import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Footer } from '@jam3/react-ui';
import checkProps from '@jam3/react-check-extra-props';
import 'default-passive-events';
import CookieConsent from 'react-cookie-consent';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';
import MainTopNav from '../../components/MainTopNav/MainTopNav';
import Pages from '../../components/Pages/Pages';
import PrefetchLink from '../../components/PrefetchLink/PrefetchLink';
import mediaContent from '../../data/media-contents';
import { setPreviousRoute } from '../../redux/modules/app';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

import settings from '../../data/settings';
import mainNavData from '../../data/main-nav';
import footerData from '../../data/footer';
import routePages from '../../routes/pages';
import backupData from '../../data/backup-data.json';

Modal.setAppElement('#root');

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      siteData: null,
      modalOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.initModalDebounced = debounce(this.openModal, 1600);
  }

  async componentDidMount() {
    await this.setData();
    this.setState({
      fetched: true
    });

    this.initModalDebounced();

    // Setup performance measure tooling
    if (process.env.NODE_ENV !== 'production') {
      const { whyDidYouUpdate } = require('why-did-you-update');

      if (document.location.search.indexOf('performance') >= 0) {
        whyDidYouUpdate(React);
      }
    }
    // window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setPreviousRoute(prevProps.location.pathname);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.handleResize);
  }

  openModal() {
    this.setState({
      modalOpen: true
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false
    });
  }

  async setData() {
    const fetchedData = {};
    const pages = `${settings.cms}pages?per_page=16`;
    await fetch(pages)
      .then(response => response.json())
      .then(data => {
        if (data.length) {
          routePages.forEach(pageName => {
            const pageData = data.filter(page => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          this.setState({
            siteData: fetchedData
          });
        } else {
          routePages.forEach(pageName => {
            const pageData = backupData.filter(page => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          this.setState({
            siteData: fetchedData
          });
        }
      })
      .catch(error => {
        routePages.forEach(pageName => {
          const pageData = backupData.filter(page => page.slug === pageName);
          if (pageData[0]) {
            fetchedData[pageData[0].slug] = pageData[0];
          }
        });
        this.setState({
          siteData: fetchedData
        });
      });
  }

  render() {
    return (
      <Fragment>
        <MainTopNav logoJue={mainNavData.logoJue} links={mainNavData.links} linkComponent={PrefetchLink} />
        {this.state.siteData && (
          <Fragment>
            <Pages siteData={this.state.siteData} />
            <Footer {...footerData} linkComponent={PrefetchLink} />
          </Fragment>
        )}
        <Modal isOpen={this.state.modalOpen} className="Popup">
          <button onClick={this.closeModal}>
            <CloseIcon />
          </button>
          <img src={mediaContent.fusion} alt="Fusion" />
        </Modal>
        <CookieConsent disableStyles={true} buttonText={'Acconsento'}>
          {"Questo sito utilizza i cookie per migliorare l'esperienza dell'utente."}
        </CookieConsent>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setPreviousRoute: val => dispatch(setPreviousRoute(val))
  };
};

App.propTypes = checkProps({
  setPreviousRoute: PropTypes.func.isRequired
});

App.defaultProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
