// Polyfills index

// IE 11
import 'react-app-polyfill/ie11';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Object.entries';
// import 'mdn-polyfills/Object.assign';
// import 'mdn-polyfills/Node.prototype.append';
// import 'mdn-polyfills/NodeList.prototype.forEach';
import 'smoothscroll-polyfill';

// IE 10
import 'core-js/es/map';
import 'core-js/es/set';

// IE 9
// import 'raf/polyfill';
// import 'classlist.js';

// eslint-disable-next-line no-extend-native
Number.prototype.map = function(in_min, in_max, out_min, out_max) {
  return ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};
